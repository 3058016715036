@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
	font-family: "Inter", sans-serif;
	font-size: 14px;
}

@media (min-width: 601px) {
	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100dvh;
		background-color: #F8F9FC;
	}

	.login-card {
		background: #ffffff;
		padding: 2em;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		text-align: center;
		width: 420px;
		font-family: 'Inter', sans-serif;
	}

	.app {
		height: 100dvh;
	}
}

@media (max-width: 991px) {
	.login-bigContainer {
		height: 100dvh;
		align-items: center;
	}

	.login-card {
		background: #ffffff;
		padding: 1em;
		text-align: center;
		width: 100%;
		font-family: 'Inter', sans-serif;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}

	.app {
		height: auto !important;
	}

	.content {
		margin-left: 0px !important;
		padding: 0px 15px 10px !important;
	}

	.teachersPage .content {
		padding: 0px 5px 10px !important;
	}

	/* 	::-webkit-scrollbar {
		display: none;
	} */

	::-webkit-scrollbar {
		width: 4px !important;
		height: 4px !important;
	}

	.mobile-cards {
		padding: 10px !important;
	}

	.h-100-3,
	.h-100-2,
	.h-100-1 {
		height: calc(100dvh - 75px) !important;
	}

	.calendarContainer {
		min-height: 230px;
	}

	.studentCardHeight,
	.heightDataStudentContainer {
		height: ayto !important;
	}


}

@media (max-width: 730px) {
	.login-bigContainer {
		height: 100dvh;
		align-items: center;
	}

	.login-card {
		background: #ffffff;
		padding: 1em;
		text-align: center;
		width: 100%;
		font-family: 'Inter', sans-serif;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}

	.app {
		height: auto !important;
	}

	.content {
		margin-left: 0px !important;
		padding: 0px 15px 10px !important;
	}

	/* 	::-webkit-scrollbar {
		display: none;
	} */

	::-webkit-scrollbar {
		width: 4px !important;
		height: 4px !important;
	}

	.mobile-cards {
		padding: 10px !important;
	}

	.h-100-3,
	.h-100-2,
	.h-100-1 {
		height: calc(100dvh - 75px) !important;
	}

	.studentCardHeight,
	.heightDataStudentContainer {
		height: auto !important;
	}

}

/* Sobrescribir preferencia de movimiento reducido */
@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		animation: progress-bar-stripes 1s linear infinite !important;
	}
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100dvh;
	background-color: #F8F9FC;
}

::-webkit-scrollbar {
	height: 8px;
	width: 8px;
	margin: 10px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
	background: #F0F0F2;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
	background: #BDBDBD;
	border-radius: 5px;
}

.login-card #btnLogin {
	width: 100%;
	height: 50px;
}

.login-card #btnGoogle {
	width: 100%;
	height: 50px;
}

.btn-primary {
	background-color: #3680F4 !important;
	border-color: #3680F4 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-primary:focus {
	background-color: #0D72B5 !important;
	border-color: #0D72B5 !important;
}

.btn-primary:hover {
	background-color: #0D72B5 !important;
	border-color: #0D72B5 !important;
}

.link-primary {
	color: #3680F4 !important;
	text-decoration: none !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.link-primary:focus {
	color: #0D72B5 !important;
}

.assitanceDrop:hover {
	color: #0d6efd;
}

.link-primary:hover {
	color: #0D72B5 !important;
}

.btn-light {
	background-color: #F0F0F2 !important;
	border-color: #F0F0F2 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-light:focus {
	background-color: #cdcdcd !important;
	border-color: #cdcdcd !important;
}

.btn-light:hover {
	background-color: #dadada !important;
	border-color: #dadada !important;
}

.btn-info {
	background-color: #CFD4E3 !important;
	border-color: #CFD4E3 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-info:focus {
	background-color: #DADADA !important;
	border-color: #DADADA !important;
}

.btn-info:hover {
	background-color: #DADADA !important;
	border-color: #DADADA !important;
}

.link-info {
	color: #CFD4E3 !important;
	text-decoration: none !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.link-info:focus {
	color: #DADADA !important;
}

.link-info:hover {
	color: #DADADA !important;
}

.app {
	display: flex;
	/* height: 100dvh; */
	padding: 0 !important;
	max-width: 100vw;
	overflow: hidden;
}

.dropArrow {
	margin-left: 10px;
	color: #4b4b4b;
	vertical-align: bottom;
	margin-bottom: 2px;
}

.navbar-vertical {
	width: 240px;
	height: 100dvh;
	background-color: white;
	border-right: 1px solid #DFDFDF;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: auto;
}

.navbar-horizontal {
	width: 100vw;
	height: 60px;
	background-color: white;
	border-right: 1px solid #DFDFDF;
	position: fixed;
	overflow-y: auto;
}

.navbar-user {
	/* 	position: absolute;
	bottom: 15px; */
	width: 100%;
	text-align: center;
	padding: 15px;
}

.navbar-user-mobile {
	width: 100%;
	text-align: center;
}

.navbar-user-interior {
	background-color: #cfd4e3;
	border-radius: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.navbar-item a {
	text-decoration: none !important;
	color: black;
}

.navbar-item a:hover,
.navbar-drop:hover,
.navbar-drop:hover .dropArrow {
	color: #3680F4;
}

.navbar-icon-content {
	min-width: 20px;
}

.navbar-icon {
	background-color: #cfd4e3;
	border-radius: 5px;
	padding: 5px;
}

.navbar-menu-active {
	color: #3680F4 !important;
	font-weight: 700;
}

.navbar-item:active {
	color: #3680F4;
}

.content {
	flex-grow: 1;
	padding: 20px 30px;
	overflow-y: auto;
	height: 100dvh;
	margin-left: 240px;
	background: #F2F3F9;
	overflow-x: hidden;
}

.content-sm {
	flex-grow: 1;
	padding: 10px 15px;
	overflow-y: auto;
	height: 100dvh;
	background: #F2F3F9;
	padding-top: 70px;
	overflow-x: hidden;
	box-sizing: border-box;
}

.toolbar {
	background: white;
	border-radius: 50px;
	height: 50px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid #DFDFDF;
	box-shadow: 1px 3px 5px #e6e6e6 !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cards {
	/* box-shadow: 1px 3px 5px #e6e6e6 !important; */
	border-radius: 15px !important;
	padding: 20px;
}

.cards.content {
	padding: 0 !important;
}

.content-table.head {
	text-align: center;
	border-bottom: 2px solid #A0A0A0;
	background-color: #EBEBEB;
	padding-top: 8px;
	padding-bottom: 8px;
	font-weight: bold;
}

.content-table.rows {
	/* 	padding-top: 8px;
	padding-bottom: 8px; */
	border-bottom: 2px solid #EBEBEB;
}

.middle {
	text-align: center;
}

.content-table.rows.right {
	text-align: right;
}

.content-table.status {
	/* padding: 12px; */
	font-size: 1rem;
	font-weight: 400 !important;
}

.content-options.content {
	height: 72px;
	border-bottom: 3px solid #EBEBEB !important;
}

.content-options.rows.left {
	padding-left: 32px;
	width: 50%;
}

.content-options.rows.right {
	padding-right: 32px;
	text-align: right;
}

.column-title {
	background-color: #49A5DF;
	text-align: center;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 10px 10px 0px 0px;
	color: white
}

.column-content {
	background-color: #f9f9f9;
	padding: 16px;
	height: 100%;
}

.column-content-nh {
	background-color: #FAFAFA;
	padding: 16px;
}

.fc-col-header-cell-cushion {
	text-decoration: none !important;
}

.fc-daygrid-day-number {
	text-decoration: none !important;
}

/* .table-bordered>:not(caption)>*>* {
	border: none !important;
} */

.file.element {
	position: relative;
	/* display: flex; */
	/* align-items: center; */
	width: 100%;
	padding: 0.5rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: 0.25rem;
}



.statusCard {
	border-right: none !important;
	border-top: none !important;
	border-bottom: none !important;
	height: 130px !important;
	box-shadow: 1px 3px 5px #CFCFCF !important;
}

.statusCard.green {
	border-left: 5px solid #28A745 !important;
}

.statusCard.yellow {
	border-left: 5px solid #FFC008 !important;
}

.statusCard.red {
	border-left: 5px solid #DC3545 !important;
}

.statusCard.administration {
	height: 108px !important;
}

.avatar-10 {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.avatar-20 {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.avatar-30 {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.avatar-40 {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.avatar-60 {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}

.avatar-70 {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

.avatar-200 {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}

.text-error {
	background: red !important;
	color: white !important;
}

.align-center-vertically {
	display: flex !important;
	align-items: center;
}

.align-center-vertically-v2 {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

/*Home cards css*/

.home-mini-cards {
	max-height: 100%;
	padding: 10px 5px;
}

.card-icon-content {
	scale: 120%;
}

/* Button Style */

.prev-next-icon {
	color: #BDBDBD;
	scale: 200%;
}

.smallFontSize {
	font-size: 11px;
	color: gray;
}

.interCards p {
	margin-bottom: 0px;
}

.course-col {
	min-height: 100%;
	max-height: 100%;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 12% !important;
	padding: 0px 5px;
}

.gray-back {
	background-color: #BDBDBD;
}

.blue-back {
	background-color: #ABD5FF;
}

.blueTasky-back {
	background-color: #0480FD;
}

.whitedark-back {
	background-color: #F0F0F2;
}

.iconCol {
	max-width: 7% !important;
	padding: 0px !important;
	margin-right: 5px;
	align-self: center;
}

.slideContent {
	overflow-y: auto;
}

.calendarContainer {
	height: calc(55dvh - 197px);
}

.interCards {
	margin-bottom: 5px;
}

.green {
	color: #8FDFBB;
}

.red {
	color: #FF9CA5;
}

.greenBack {
	background-color: #8FDFBB !important;
}

.redBack {
	background-color: #FF9CA5 !important;
}

.greyBack {
	background-color: rgb(240, 240, 240) !important;
}

.yellowBack {
	background-color: rgb(233, 255, 133) !important;
}

.course-icon {
	scale: 115%;
}

.row {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.circle-container {
	width: 35px;
	height: 35px;
	overflow: hidden;
	border-radius: 50%;
	background-color: #E5E6E9;
	display: flex;
	justify-content: center;
}

.studentImg {
	width: 34px;
	height: 34px;
	border: 1px solid #3f3f3f;
	display: flex;
	justify-content: center;
}

.circle-container img {
	height: 100%;
	display: block;
}

.circle-container p {
	color: #6D6D6D;
	text-align: center;
	margin-top: 5px;
}

.date {
	margin-bottom: 5px;
	margin-left: 10px;
	color: #6D6D6D;
}

.dots-icon {
	color: #737373;
	scale: 120%;
}

.fitContentWidth {
	max-width: fit-content !important;
}

.toolBarIcon {
	margin-right: 15px;
}

.sideColor {
	width: 10px;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
}

.campo-input {
	border-radius: 0.25rem;
	padding: 5px 5px;
	margin: 10px 0px;
	border: 1px solid #6D6D6D !important;
	display: block;
	width: 100%;
	outline-style: none;
}

.calCreateButton {
	border-radius: 10px;
	border: 3px solid #F0F0F2;
	padding: 5px 10px;
}

.switchButton {
	border-radius: 10px;
	border: 3px solid #F0F0F2;
	padding: 5px 10px;
}

.calButtonCont {
	display: flex;
	background-color: #F0F0F2;
	border-radius: 10px;
	width: fit-content;
	flex-wrap: nowrap;
}

.switchButtonContainer {
	display: flex;
	background-color: #F0F0F2;
	border-radius: 10px;
	width: fit-content;
	flex-wrap: nowrap;
}

.modal-content {
	border-radius: 15px !important;
	border: 0px !important;
}

.dropDownIcon {
	margin-right: 10px;
	font-size: 18px;
}

.modal-title {
	font-size: 20px !important;
}

.modal-body {
	border-bottom-left-radius: 15px !important;
	border-bottom-right-radius: 15px !important;
	padding: 20px 20px 20px 20px !important;
}

.modal-footer {
	background-color: white;
	border-bottom-right-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
}

.modal-header {
	border-top-right-radius: 15px !important;
	border-top-left-radius: 15px !important;
}

.iconModalCol {
	max-width: 30px !important;
}

.iconModalCol svg {
	margin-top: 18px;
}

.iconModaColSpecial svg {
	margin-top: 11px;
}

.remiderCross {
	top: 10px;
	color: grey;
	cursor: 'pointer';
}

.remiderCross:hover {
	color: #3680F4;
}

.endRow {
	display: flex;
	justify-content: flex-end;
}

.flexEnd {
	display: flex;
	align-items: flex-end;
}

.arrowStatics {
	scale: 120%;
	padding: 0px 10px 10px
}

.marginSep {
	margin: 30px 0px !important;
}

.assistanceButton {
	border-radius: 5px;
	margin: 3px;
	color: black !important;
	border: none !important;
}

.assistGrey {
	background-color: white !important;
}

.staticCards {
	min-width: fit-content !important;
}

.wrapCont {
	flex-wrap: wrap;
}

.headerContainer th,
.headerContainer td {
	align-self: center;
	text-align: center;
	vertical-align: middle;
	border-collapse: separate;
	border: 1px solid white;
}

.headerContainer {
	border-spacing: 0px;
}

.whiteText {
	color: white;
}

.bigAssitButton {
	text-align: center;
	padding: 15px 0 !important;
	max-width: 55px;
	width: 55px;
}

.slideTable {
	width: 100%;
	overflow-x: auto;
	position: relative;
	height: 100%;
}

.sticky-row {
	position: sticky;
	top: -2px;
	z-index: 2;
	border: 1px solid white;
}

.sticky-column {
	position: sticky;
	left: -2px;
	z-index: 1;
	border: 1px solid white;
}

.tableContainer {
	height: 60%;
}

.smallButton {
	width: 46px;
}

.navBarDrop {
	list-style: none;
	padding: 3px 0px 0px 10px;
}

.navBarDrop li {
	padding-bottom: 3px;
}

.dropDownIcon {
	width: 20px;
}

.dropDownCal {
	text-align: left !important;
	width: 200px;
}

.dropdown-toggle::after {
	right: 0px;
}

.react-datepicker__input-container input {
	padding: 4px 12px;
}

.table {
	text-align: center;
}

.editGrades {
	background-color: #E5E6E9;
	color: #3680F4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.configCards {
	padding: 20px 15px;
}

.iconConfig {
	margin-right: 10px;
}

.configLink {
	text-decoration: none !important;
	color: black;
}

.configLink:hover {
	color: rgb(43, 128, 255);
}

.react-datepicker__input-container {
	width: auto !important;
}

.framesContainer td {
	border-width: 0px 0px !important;
}

.frameDateContainer .react-datepicker__input-container {
	width: 100% !important;
}

.smallColTable {
	width: 15%;
}

.roundedText-v2 {
	border-radius: 20px;
	padding: 2px 10px;
}

.justify-content-center {
	display: flex;
	justify-content: center;
}

.space-around {
	display: flex;
	justify-content: space-around;
}

.mr-10 {
	margin-right: 10px;
}

.verticalAlignMiddle {
	vertical-align: middle;
}


td {
	vertical-align: middle;
}

.infoStudentContainer {
	border-radius: 15px;
	border: 3px solid #F0F0F2;
	display: flex;
	width: max-content;
}

.infoStudentContainer span {
	padding: 0px 15px;
}

.iconStudent {
	background-color: #F0F0F2;
	border-radius: 10px;
}

.iconStudent svg {
	padding: 1px 12px;
}

.dropDownCal input {
	border-width: 0px;
}

.dropDownCal input:focus {
	border-width: 0px;
	outline: 0px;
}

.dropDownCal .btn-primary:hover {
	background-color: white !important;
	border-color: white !important;
}

.headerBlue tr td {
	background-color: #3680F4 !important;
}

.fs-8 {
	font-size: 8px !important;
}

.fs-11 {
	font-size: 11px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-24 {
	font-size: 24px;
}

.fs-38 {
	font-size: 38px !important;
}

.fw-700 {
	font-weight: 700 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.textInputGray {
	border: 1px solid #6D6D6D !important;
}

.h-75 {
	height: 75%;
}

.tableRows {
	background: #F0F0F0;
	height: 43px;
}

.tableRowsDarkLeft {
	background: #F0F0F0;
	height: 43px;
	border-left: 2px solid #353535;
}

.tableRowsBackground {
	background: #F0F0F0;
}

.disabledTableRowsBackground {
	background: #bbbbbb;
}

.statusBadge {
	padding: 10px !important;
	font-weight: 400 !important;
	font-size: 14px;
}

.assistanceBadge {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
	font-weight: 400 !important;
	font-size: 14px;
}

.dropdownToggle {
	height: 35px;
}

.br-15 {
	border-radius: 15px;
}

.br-025 {
	border-radius: 0.25rem;
}

.dropdownButton .dropdown-toggle:hover,
.dropdownButton .btn-primary:focus,
.dropdownButton .dropdown-toggle,
.dropdownButton .btn-primary {
	background-color: #ffffff !important;
	color: #000000 !important;
	border: 1px solid #6C757D !important;
	box-shadow: none !important;
}

.dropdownFilter.dropdown-toggle::after {
	position: absolute !important;
	right: 15px !important;
	top: 15px !important;
}

.dropdownFilter {
	padding-right: 30px;
}

.dropdownButton .dropdownFilter {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}

.wi-100p {
	width: 100% !important;
}

.wi-350 {
	width: 355px !important;
}

.wi-400 {
	width: 400px !important;
}

.wi-500 {
	width: 500px !important;
}

.wi-600 {
	width: 600px !important;
}

.wi-300 {
	width: 300px !important;
}

.wi-200 {
	width: 200px !important;
}

.wi-250 {
	width: 250px !important;
}

.wi-146 {
	width: 146px !important;
}

.wi-147 {
	width: 147px !important;
}

.wi-150 {
	width: 150px !important;
}

.wi-175 {
	width: 175px !important;
}

.wi-120 {
	width: 120px !important;
}

.wi-100 {
	width: 100px !important;
}

.wi-70 {
	width: 70px !important;
}

.wi-50 {
	width: 50px !important;
}

.wi-30 {
	width: 30px;
}

.w-div6 {
	width: calc(100% / 6);
}

.txt-left {
	text-align: left !important;
}

.input-text {
	background-color: #ffffff !important;
	color: #000000 !important;
	border: 1px solid #6C757D !important;
	box-shadow: none !important;
	border-radius: 0.25rem;
}

.carousel-control-next {
	justify-content: flex-end !important;
	margin-right: 20px;
}

.carousel-control-prev {
	justify-content: flex-start !important;
	margin-left: 20px;
}

.carousel-indicators {
	margin-bottom: 0px !important;
}

.notFoundIcon {
	scale: 200%;
	max-width: fit-content !important;
}

.notFoundContainer {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: gray;
}

.overflow-hidden {
	overflow: hidden;
}

.greyBackCard {
	background-color: rgb(240, 240, 240) !important;
	border: none !important;
}

.p-action-plus-button {
	padding: 5px 0px 5px 10px !important;
}

.borderDiv {
	border: 1px solid rgba(0, 0, 0, 0.125);
	background-color: rgb(240, 240, 240);
}

.studentLink:hover {
	color: #0480FD;
}

.link-no-deco {
	text-decoration: none;
	color: #000000;
}

.link-no-hover:hover {
	color: black;
}

.link-no-hover:active {
	color: white;
}

.studentCardHeight {
	height: calc(50dvh - 65px);
}

.studentCardContainer {
	height: calc(41dvh - 112px);
}

.table> :not(:last-child)> :last-child>* {
	border-bottom-color: rgb(222, 226, 230) !important;
}

.dotsRow:active {
	color: white !important;
}

.entireRow {
	border-radius: 8px;
	background: #F0F0F0;
}

.form-control-color {
	max-width: 300px !important;
	height: 11px !important;
}

.tableHtml td {
	border-style: none;
}

.roundedRowEnd {
	border-radius: 0px 8px 8px 0px !important;
}

.hg-50 {
	height: 50px;
}

.cropper-container {
	height: 400px;
	width: 100%;
	position: relative;
	border: 1px solid #ccc;
}

.button-container {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}

.mt-100 {
	margin-top: 100px;
}

.loading-100 {
	margin-top: 100px;
	margin-bottom: 100px;
}

.loading-200 {
	margin-top: 200px;
	margin-bottom: 200px;
}

.no-wrap {
	flex-wrap: nowrap;
}

.search-results {
	border-radius: 5px;
	position: absolute;
	z-index: 1;
	background-color: white;
	border: 1px solid #ccc;
	border-top: none;
	width: 300px;
	padding: 5px;
}

.userSelectedModal {
	border-radius: 10px;
	background-color: rgb(216, 216, 216);
	padding: 2px 10px;
}

.h-85 {
	height: 84% !important;
}

.dropdown-toggle-no-arrow::after {
	display: none !important;
}

.totalWidth {
	min-width: 100%;
}

.avatarToEdit:hover {
	filter: brightness(0.5);
}

.communication-card p,
.communication-card ul,
.communication-card ol {
	margin-bottom: 0px !important;
}

.custom-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1059;
}

.meeting-card {
	background: #d3d3d3;
	border-radius: 8px;
}

.emoji-reaction-container {
	align-items: center;
	position: relative;
}

.reactions {
	position: absolute;
	bottom: -19px;
	right: 10px;
	display: flex;
	border-radius: 10px;
	background-color: white;
	border: 1px solid #0480FD;
	padding: 1px 3px;
	width: fit-content;
}

.reaction {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.emoji-picker {
	position: absolute;
	top: -26px;
	right: 0px;
	z-index: 1000;
	display: flex;
	flex-wrap: nowrap;
	background: white;
	border: 1px solid #ccc;
	padding: 0px;
	border-radius: 5px;
}

.emoji-button {
	background: none;
	border: none;
	font-size: 16px;
	cursor: pointer;
	margin: 0px;
}

.emoji-button:hover {
	scale: 130%;
}

.textMessageInput {
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;
}

.textMessageInput:focus {
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;

}

.inputMessageRow {
	border: 1px solid transparent;
	align-self: flex-end;
}

.focusedMessageInput {
	border-color: #0480FD;
}

.chat-message {
	display: flex;
	align-items: flex-end;
	margin-bottom: 5px;
}

.message-bubble {
	padding: 10px 15px;
	border-radius: 15px;
	max-width: 80%;
	word-wrap: break-word;
}

.me-bubble {
	background-color: #0480FD;
	color: white;
	border-top-right-radius: 0;
}

.them-bubble {
	background-color: #6c757d;
	color: white;
	border-top-left-radius: 0;
}

.sendMessageIcon:hover,
.blueHover:hover,
.personListChat:hover .personNameChatList {
	color: #0480FD;
}

.blueHoverDownload:hover {
	color: #0480FD;
	background-color: white;
}

.breachesButton {
	text-align: center;
	padding: 5px 0 !important;
	max-width: 110px;
	width: 110px;
	font-size: 14px;
}

.mh-40 {
	min-height: 40px;
}

.modal-body .col-md-6 {
	min-width: min-content;
}

.dropdown-toggle:disabled {
	background-color: #e9ecef !important;
	opacity: 1;
}

.avatar-wrapper {
	position: relative;
	display: inline-block;
}

.avatar-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	/* Transparencia negra */
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	/* Siempre visible cuando se renderiza */
}

.textDots {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.twoLinesText {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	white-space: normal !important;
}

.pointer {
	cursor: pointer;
}

.accordion-button {
	background-color: rgb(240, 240, 240) !important;
	border-radius: 8px !important;
}

.accordion-button:not(.collapsed) {
	color: #0480FD;
}

.greyHover:hover {
	color: #6C757D
}

.commentContentReports {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.helpCard:hover p {
	color: #0480FD;
}

.h-100-1 {
	/* Un row */
	height: calc(100dvh - 125px);
}

.h-100-2 {
	/* Dos row */
	height: calc(100dvh - 175px);
}

.h-100-3 {
	/* Tres row */
	height: calc(100dvh - 215px);
}

.h-50-1 {
	/* Un row */
	height: calc(50dvh - 70px);
}

.dropdown-item {
	white-space: 'break-spaces';
}

.rg-8 {
	row-gap: 8px;
}

.cg-8 {
	column-gap: 8px;
}

.btn-outline-info {
	background-color: transparent !important;
	color: rgb(94, 94, 94) !important;
	border-color: #CFD4E3 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-outline-info:focus,
.btn-outline-info:hover {
	background-color: #CFD4E3 !important;
	border-color: #CFD4E3 !important;
	color: black;
}

.btn-outline-info:active {
	background-color: #DADADA !important;
	border-color: #DADADA !important;
	color: black;
}

.link-outline-info {
	text-decoration: none !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.h-45 {
	height: 45dvh !important;
}

.heightDataStudentContainer {
	height: calc(100dvh - 115px);
}

.react-datepicker__time-container .react-datepicker-time__header {
	visibility: hidden;
	position: relative;
}

.react-datepicker__time-container .react-datepicker-time__header::after {
	content: 'Hora';
	visibility: visible;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	line-height: 16px;
}

.notificationCard:hover .notificationTitle {
	color: #0480FD;
}

.bigSwitch input {
	width: 35px !important;
	height: 20px !important;
}

.flex {
	display: flex;
}

.j-s-between {
	justify-content: space-between;
}

.wi-50p {
	width: 50%;
}

.form-select {
	background-color: #ffffff !important;
	color: #000000 !important;
	border: 1px solid #6C757D !important;
	box-shadow: none !important;
}

.mgrpdf-navigation {
	border-radius: 5px;
}

.mgrpdf__wrapper>div:first-child {
	overflow: auto;
}

.select-role-school {
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
	background: white !important;
	border: 2px solid #3680F4;
	font-weight: 400;
	font-size: 12px;
	color: black;
}

.custom-progress-bar {
	transition: width 0.6s ease;
}

.rg-10{
	row-gap: 10px;
}

.cg-10 {
	column-gap: 10px;
}

.dropdown-toggle.no-arrow::after {
    content: none !important;
}

.greyHover:hover {
	background-color: rgb(200, 200, 200) !important;
	color: black;
}

.info {
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	font-size: 14px;
	z-index: 1000;
}

.leaflet-container a {
	color: black !important;
}

.leaflet-container a:hover {
	color: #0480FD !important;
}


.colorBlue {
	color:rgb(43, 128, 255)
}

.cg-8 {column-gap: 8px;}
